import { defaultConfiguration } from '../../config/defaultConfiguration.js';
import { injectAssetTextureMap } from './assetTextureMap.js';
import { add_desk_reserve_demo_layer } from './deskReservations.js';
import { injectCleaningLayer } from './layers/cleaning.js';
import { injectBedLayer, injectMedicalEquipmentLayer, injectWheelchairLayer } from './layers/hospital.js';
import { mergeConfig } from './mergeConfig.js';

// eslint-disable-next-line no-undef
const baseURL = import.meta.env.VITE_EB_SERVER;
// eslint-disable-next-line no-undef
const ASSET_URL = import.meta.env.VITE_ASSET_HOST;

const init = async () => {
  const eb_core = window.eb_core;
  const eb_map = window.eb_map;
  if (!eb_core) throw new Error('eb_core not available in window during init');
  if (!eb_map) throw new Error('eb_map not available in window during init');

  const eb = (window.eb = eb_core.init());
  eb.setBaseConfig(mergeConfig(eb.getBaseConfig(), defaultConfiguration));
  eb.selectServer(baseURL);
  eb_map.init(eb);
  injectAssetTextureMap();
  injectMedicalEquipmentLayer(); // inject layers
  injectBedLayer();
  injectWheelchairLayer();
  injectCleaningLayer();
  eb.map.set_base_resource_path(ASSET_URL);
  const canvas = document.getElementById('map_area');
  const font_description = 'Source Sans Pro';
  eb.map.start(canvas, font_description);
  add_desk_reserve_demo_layer(eb, eb_map, eb_core);

  window.renderUI({ eb });
};

const eb_map_version = '0.23';
const eb_core_version = '0.16';

/**
 * EB map canvas is dependent on fonts being fully loaded,
 * before calling the application init. Init cannot be called before
 * all scripts have been loaded
 */
let domResolve;
const domReady = new Promise(function (resolve) {
  domResolve = resolve;
});
document.addEventListener('DOMContentLoaded', domResolve);
Promise.all([
  document.fonts.ready,
  domReady,
]).then(init);
